import React from 'react'
import PropTypes from 'prop-types'
import { StyledIconButton } from './IconButton.styled'

export const ICONBUTTON_STATE = {
	ACTIVE: "Active",
	INACTIVE: "Inactive",
}

export default function IconButton({
	id, label, icon, disabled,
	onClick,
	...props
}) {
	const btn_icon = props.children === undefined ? icon : props.children
	return <StyledIconButton
		id={id}
		title={label}
		disabled={disabled}
		{...props}
		onClick={(event) => {
			event.stopPropagation()
			onClick(event)
		}}
	>
		{btn_icon}
	</StyledIconButton>
}

IconButton.propTypes = {
	/**
	 * Component ID
	 */
	id: PropTypes.string,
	/**
	 * Component icon object
	 */
	icon: PropTypes.object,
	/**
	 * Component label
	 */
	label: PropTypes.string,
	/**
	 * Component state
	 */
	state: PropTypes.PropTypes.oneOf(Object.values(ICONBUTTON_STATE)),
	/**
	 * Component click handle event
	 */
	onClick: PropTypes.func,
}

IconButton.defaultProps = {
	id: undefined,
	icon: undefined,
	label: undefined,
	state: ICONBUTTON_STATE.ACTIVE,
	onClick: (event) => { },
}
