import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background: linear-gradient(180deg, #002a66 0%, #001741 100%);
  padding-bottom: 40px;
`

export const FooterContainer = styled.div`
  display: grid;
  width: 60vw;
  margin: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1vw;

  img {
    grid-column: 1/-1;
    margin-top: 40px;
    margin-bottom: 40px;
    width: auto;
  }

  @media screen and (max-width: 920px) {
    width: 80vw;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;

    img {
      grid-column: 1/-1;
      margin-top: 20px;
      margin-bottom: -20px;
    }
  }

  @media screen and (max-width: 400px) {
    width: 90vw;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    img {
      width: 50%;
      margin-bottom: 0px;
    }
  }
`

export const FooterDiv = styled.div`
  display: grid;
  align-items: start;
  align-content: start;
  grid-column: span 3;

  a,
  p {
    color: #a9c6ea;
    text-decoration: inherit;
    opacity: 0.5;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
  }

  a:hover{
    opacity: 1;
  }

  @media screen and (max-width: 920px) {
    grid-column: span 1;
  }

  @media screen and (max-width: 400px) {
    a,
    p {
      font-size: 0.9em;
      line-height: 1.25em;
    }
  }
`