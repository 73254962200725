import styled from "styled-components";

import {
    handleColor,
    handleMarginBottom,
    handleMarginTop, handlePadding,
    handleTextAlign
} from "../../general/typography/TypographyFunctions";

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: ${props => props.toolong ? "2.7rem" : "var(--title)"};
  line-height: var(--title-lh);
  text-transform: uppercase;
  color: ${({color}) => handleColor(color)};

  &:first-child {
    margin-top: ${props => props.storybook ? "0" : "var(--large)"};
  }

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
`

export const NewTitle = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: var(--new-title);
  line-height: var(--new-title-lh);
  color: ${({color}) => handleColor(color)};
  
  strong{
    color: white;
    font-weight: bold;
  }
  
  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
`

export const Header2 = styled.h2`
  font-style: normal;
  font-weight: ${props => props.light ? "400" : "bold"};
  font-size: ${props => props.small ? "var(--h2-small)" : "var(--h2)"};
  line-height: var(--h2-lh);
  text-transform: ${props => props.upper ? "uppercase" : ""};
  color: ${({color}) => handleColor(color)};

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
  
  strong{
    font-weight: bold !important;
  }
`

export const Header2New2 = styled.h2`
  font-style: normal;
  font-weight: ${props => props.light ? "400" : "bold"};
  font-size: ${props => props.small ? "var(--h2-small)" : "var(--h2-new)"};
  line-height: var(--h2-lh);
  text-transform: ${props => props.upper ? "uppercase" : ""};
  color: ${({color}) => handleColor(color)};

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
  
  strong{
    font-weight: bold !important;
  }
`

export const Header2New = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 130%;
  color: ${({color}) => handleColor(color)};

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
  
  strong{
    font-weight: bold !important;
  }
`

export const Header2Menu = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: var(--h2-menu);
  line-height: var(--h2-menu-lh);
  color: ${({color}) => handleColor(color)};
  padding-left: ${props => props.storybook ? "0" : "0.9rem"};

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
`

export const Header3 = styled.h3`
  font-style: normal;
  font-weight: ${props => props.light ? "400" : "bold"};
  font-size: ${props => props.small ? "var(--h2-menu)" : "var(--h3)"};
  line-height: var(--h3-lh);
  text-transform: ${props => props.upper ? "uppercase" : "unset"};
  color: ${({color}) => handleColor(color)};

  br{
    margin-bottom: ${props => props.no ? "0" : "1rem"};
  }
  
  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
`

export const Body = styled.p`
  font-style: ${props => props.italic ? "italic" : "normal"};
  font-weight: ${props => props.bold ? "bold" : "500"};
  font-size: ${props => props.small ? "var(--p-small)" : (props.smaller ? "var(--p-smaller)" : "var(--p)")};
  line-height: ${props => props.small ? "var(--p-small-lh)" : "var(--p-lh)"};
  text-transform: ${props => props.upper ? "uppercase" : "unset"};
  color: ${({color}) => handleColor(color)};
  cursor: ${props => props.pointer ? "pointer" : ""};
  
  br{
    margin-bottom: ${props => props.no ? "0" : "1rem"};
  }

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
  padding: ${({padding}) => handlePadding(padding)};
`

export const BodyNew = styled.p`
  font-style: ${props => props.italic ? "italic" : "normal"};
  font-weight: ${props => props.bold ? "bold" : "300"};
  font-size: ${props => props.small ? "var(--p-small)" : (props.smaller ? "var(--p-smaller)" : "var(--p)")};
  line-height: ${props => props.small ? "var(--p-small-lh)" : "var(--p-lh)"};
  text-transform: ${props => props.upper ? "uppercase" : "unset"};
  color: ${({color}) => handleColor(color)};
  cursor: ${props => props.pointer ? "pointer" : ""};
  
  br{
    margin-bottom: ${props => props.no ? "0" : "1rem"};
  }

  strong{
    color: white;
    font-weight: bold !important;
  }
  
  a{
    font-weight: 500;
  }

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
  padding: ${({padding}) => handlePadding(padding)};
`

export const BodyLi = styled.li`
  font-style: normal;
  font-weight: ${props => props.bold ? "600" : "500"};
  font-size: ${props => props.small ? "var(--p-small)" : "var(--p)"};;
  line-height: ${props => props.small ? "var(--p-small-lh)" : "var(--p-lh)"};
  text-transform: ${props => props.upper ? "uppercase" : "unset"};
  color: ${({color}) => handleColor(color)};

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
`

export const SubmenuText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: var(--submenu);
  line-height: var(--submenu-lh);
  color: ${({color}) => handleColor(color)};

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
`

export const ButtonFont = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: var(--button);
  line-height: var(--button-lh);
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: ${({color}) => handleColor(color)};

  /*Optional fields*/
  text-align: ${({textAlign}) => handleTextAlign(textAlign)};
  margin-top: ${({marginTop}) => handleMarginTop(marginTop)};
  margin-bottom: ${({marginBottom}) => handleMarginBottom(marginBottom)};
`

export const Span = styled.span`
  color: ${({color}) => handleColor(color)};
`

export const LineClamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`