import styled from "styled-components";
import { TOPCTA_STATE } from "../../general/TopCTA/TopCTA";

export const SidebarContainer = styled.nav`
  display: none;
  @media screen and (max-width: 1050px) {
    display: grid;
  }
`;

export const SidebarLogo = styled.img`
  position: fixed;
  z-index: 1000;
  top: ${(props) =>
    props.top_cta_state === TOPCTA_STATE.OPENED ? "72px" : "22px"};
  left: 5%;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  height: 35px;
  display: block;
`;

export const HamburgerWrapper = styled.div`
  display: none;
  @media screen and (max-width: 1050px) {
    display: block;
  }
`;

export const HamburgerIconWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: ${(props) =>
    props.top_cta_state === TOPCTA_STATE.OPENED ? "82px" : "30px"};
  left: 85%;
  width: 20px;
  height: 20px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media screen and (max-width: 1050px) {
    display: block;
  }
`;

export const HamburgerIcon = styled.span`
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
  ${(props) => {
    if (props.first) {
      return `
        top: 0px;
    `;
    } else if (props.mid) {
      return `
        top: 6px;
    `;
    } else if (props.last) {
      return `
        top: 12px;
    `;
    } else if (props.open1) {
      return `
        top: 12px;
        width: 0%;
        left: 50%;
        border: none;
    `;
    } else if (props.open2) {
      return `
        top: 6px;
        transform: rotate(45deg) !important;
        background: #083852 !important;
        border: none;
    `;
    } else if (props.open3) {
      return `
        top: 6px;
        transform: rotate(-45deg) !important;
        background: #083852 !important;
        border: none;
    `;
    } else if (props.open4) {
      return `
        top: 18px;
        width: 0%;
        left: 50%;
        border: none;
    `;
    }
  }}
`;

export const SidebarBox = styled.div`
  align-content: start;
  box-sizing: border-box;
  grid-auto-flow: row;
  padding-top: 80px;
  background: white;
  position: fixed;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 999;
  margin-left: -100%;
  display: grid;
  opacity: 0;

  ${(props) => {
    if (props.open) {
      return `
        opacity: 1;
        animation-name: MenuOpen;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;
        overflow-y: auto;
        @keyframes MenuOpen {
            0% {
                margin-left: -100%;
            }
            100% {
                margin-left: 0%;
            }
        }
    `;
    } else if (props.close) {
      return `
        opacity: 1;
        animation-name: MenuClose;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
        @keyframes MenuClose {
            0% {
                margin-left: 0%;
            }
            100% {
                margin-left: -100%;
            }
        }
    `;
    }
  }}
  a,
  p,
  a a:link,
  a:active,
  a a:visited {
    color: var(--secondary-brand);
    text-decoration: none;
    line-height: 40px;
    font-size: 16px;
    padding-left: 30px;
    display: grid;
    grid-auto-flow: column;
  }
`;

export const SidebarSubmenuArrow = styled.div`
  display: grid;
  justify-self: end;
  width: 45px;
  height: 40px;
  transition: transform 0.3s, invert 0.3s;
  cursor: pointer;
  transform: ${(props) => (props.active ? "rotate(90deg)" : "")};

  img {
    place-self: center;
  }
`;

export const SidebarSubmenuPanel = styled.div`
  background-color: white;
  max-height: ${(props) => (props.open ? props.count * 40 + "px" : "0")};
  overflow: ${(props) => (props.open ? "" : "hidden")};
  transition: ${(props) =>
    props.open ? "max-height 0.2s ease-out" : "max-height 0.2s ease-in"};

  a,
  a:active {
    padding-left: 50px !important;
    margin-left: 10px;
  }
`;

export const SidebarSubmenu = styled.div`
  display: grid;
  grid-template-columns: auto 45px;
  cursor: pointer;

  a {
    margin: 0;
    padding-left: 30px;
  }
`;
