import styled from "styled-components";
import {Link} from "gatsby";

const handleJustifySelf = justify => {
    switch (justify) {
        case "start":
            return "start";
        case "end":
            return "end";
        default:
            return "center";
    }
};

export const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  justify-content: start;
  
  @media screen and (max-width: 800px) {
    grid-auto-flow: ${props => props.toolong ? "row" : "column"};
    justify-items: ${props => props.toolong ? "center" : ""};
  }
`

const handleColor = color => {
    return "var(--" + color + ")"
};

const handleHover = color => {
    if (color === "PAS-primary") {
        return "var(--PAS-secondary)"
    } else if (color === "secondary-brand") {
        return "var(--primary-brand)"
    } else if (color === "primary-brand") {
        return "var(--primary-brand-hover)"
    } else if (color === "zorp-gpl-primary") {
        return "var(--zorp-gpl-secondary)"
    } else if (color === "PNS-primary") {
        return "var(--PNS-secondary)"
    } else if (color === "zorp-gateway-primary") {
        return "var(--zorp-gateway-secondary)"
    } else if (color === "zorp-malware-primary") {
        return "var(--zorp-malware-secondary)"
    } else if (color === "one-identity-primary") {
        return "var(--one-identity-secondary)"
    } else if (color === "quest-primary") {
        return "var(--quest-secondary)"
    } else if (color === "live-primary") {
        return "var(--live-secondary)"
    }
};

export const ButtonLink = styled(Link)`
  font-size: var(--button);
  line-height: var(--button-lh);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border-radius: 40px;
  border: none;
  padding: 10px 30px 10px 30px;
  margin: ${props => props.toolong ? "0" : "1rem 0 1rem 0"};
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  color: white;
  background-color: ${({color}) => handleColor(color)};

  &:hover {
    background-color: ${({color}) => handleHover(color)};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px 20px 10px 20px;
  }
`

export const ButtonA = styled.a`
  font-size: var(--button);
  line-height: var(--button-lh);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border-radius: 40px;
  border: none;
  padding: 10px 30px 10px 30px;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  color: white;
  background-color: ${({color}) => handleColor(color)};

  &:hover {
    background-color: ${({color}) => handleHover(color)};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px 20px 10px 20px;
  }
`

export const Button = styled.button`
  font-size: var(--button);
  line-height: var(--button-lh);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border-radius: 40px;
  border: ${props => props.filled ? "none" : "1px solid var(--primary-brand)"};
  padding: 10px 30px 10px 30px;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  transition: ${props => props.filled ? "border 0.2s, color 0.2s" : "background-color 0.2s"};
  white-space: nowrap;
  color: ${props => props.filled ? "var(--white)" : "var(--primary-brand)"};
  background-color: ${props => props.filled ? "var(--primary-brand)" : "transparent"};
  justify-self: ${({justify}) => handleJustifySelf(justify)};

  &:hover {
    color: white;
    border: ${props => props.filled ? "none" : "1px solid white"};
    background-color: ${props => props.filled ? "var(--primary-brand-hover)" : "transparent"};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px 20px 10px 20px;
  }
`

export const ButtonNew = styled.button`
  font-size: var(--button);
  line-height: var(--button-lh);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border: ${props => props.filled ? "none" : "1px solid var(--primary-brand)"};
  padding: 8px 20px;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  color: ${props => props.filled ? "var(--white)" : "var(--primary-brand)"};
  background-color: #5B8DFF;
  border-radius: 3px;
  justify-self: ${({justify}) => handleJustifySelf(justify)};

  &:hover {
    color: white;
    border: ${props => props.filled ? "none" : "1px solid white"};
    background-color: ${props => props.filled ? "var(--primary-brand-hover)" : "transparent"};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px 20px 10px 20px;
  }
`
export const ButtonNewLink = styled(Link)`
  font-size: var(--button);
  line-height: var(--button-lh);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border: none;
  padding: 8px 20px;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  color: #232C47;
  background-color: white;
  border-radius: 3px;
  justify-self: ${({justify}) => handleJustifySelf(justify)};

  &:hover {
    color: white;
    background-color: var(--primary-brand-hover);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px 20px 10px 20px;
  }
`

export const ButtonForm = styled.button`
  font-size: var(--button);
  line-height: var(--button-lh);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border-radius: 40px;
  border: none;
  padding: 10px 30px 10px 30px;
  margin: ${props => props.toolong ? "0" : "1rem 0 1rem 0"};
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  color: white;
  background-color: ${({color}) => handleColor(color)};

  &:hover {
    background-color: ${({color}) => handleHover(color)};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px 20px 10px 20px;
  }
`