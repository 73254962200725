import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */

  background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.9); /* Black w/ opacity */
  background: radial-gradient(ellipse at center, rgba(0, 42, 102, 1) 0%, rgba(0, 23, 65, 1) 70%);
`

export const ModalContent = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 2vh;
  grid-auto-flow: row;
  grid-template-rows:8fr 2fr;
  height: 100vh;
  animation-name: zoom;
  animation-duration: 0.6s;

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @media screen and(max-width: 800px){
    margin: 0;
  }
`

export const ModalContentImg = styled.img`
  margin: auto;
  display: block;
  max-width: 80vw;
  width: auto;
  height: 85vh;
`