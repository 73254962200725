import React, {useEffect} from "react";

import {ModalContent, ModalContentImg, ModalWrapper} from "../../styled/general/modal-image/ModalImageComponents";
import {Header3} from "../../styled/typography/Typography";

const ModalImage = () => {

    useEffect(()=>{
        const modal = document.getElementById("modal");
        const modalImage = document.getElementById("modal-image");
        const images = document.getElementsByClassName("image-for-modal");
        for (let image of images) {
            image.addEventListener("click", (event) => modalImageOpen(event));
        }

        function modalImageOpen(event) {
            let image = event.target;
            let captionText = document.getElementById("caption");

            modal.style.display = "grid";
            modal.tabIndex = 0;
            modal.children[0].tabIndex = 0;
            modalImage.src = image.src;
            modalImage.alt = image.alt;
            captionText.innerHTML = image.alt;
        }
        modal.onclick = function () {
            modal.style.display = "none";
        };
        document.onkeyup = function (e) {
            if(e.code === "Escape"){
                modal.style.display = "none";
            }
        };
    }, [])

    return (
        <ModalWrapper id="modal">
            <ModalContent>
                <ModalContentImg id="modal-image" alt="Modal"/>
                <Header3 id="caption" color="white" textAlign="center"/>
            </ModalContent>
        </ModalWrapper>
    )
}

export default ModalImage