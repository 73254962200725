import styled, {css} from "styled-components";

import dotted from "../../../../static/assets/dotted_img.png"
import middle from "../../../../static/assets/middle_bg.png"

const handlePadding = padding => {
    switch (padding) {
        case "noTop":
            return "0 0 8vh 0";
        case "middle_section":
            return "7vh 0 0vh 0";
        case "noBottom":
            return "8vh 0 0 0";
        case "smallerTop":
            return "3vh 0 8vh 0";
        case "first":
            return "16vh 0 8vh 0";
        default:
            return "8vh 0 8vh 0";
    }
};

const handleMinHeight = mh => {
    switch (mh) {
        case "smaller":
            return "10vh";
        case "small":
            return "20vh";
        case "medium":
            return "40vh";
        case "large":
            return "55vh";
        case "usecases":
            return "70vh";
        case "normal":
            return "60vh";
        default:
            return "80vh";
    }
};

const handleBackground = bg => {
    switch (bg) {
        case "none":
            return "unset";
        case "middle":
            return "var(--bg-gradient--middle)";
        case "demo":
            return "#2A4D80";
        default:
            return "var(--bg-radial-gradient)";
    }
};

export const Background = styled.section`
  background: ${({customBg}) => handleBackground(customBg)};

  ${props => props.customBg === "hero" && css`
    background-repeat: no-repeat;
    background-position: top;
    background-color: transparent;
    background-size: cover;
    background-image: ${props => "url('" + props.image + "') !important"};
  `}

  ${props => props.customBg === "palp" && css`
    background-repeat: no-repeat;
    background-position: center right;
    background-color: transparent;
    background-size: 70%;
    background-image: ${props => "url('" + props.image + "') !important"};

    @media (max-width: 800px) {
      background-image: unset !important;
    }
  `}
`

export const BackgroundNew = styled.section`
  ${props => (props.id === 'api-header-section' || props.id === 'footer-section') && css`
    background: url(${dotted}), radial-gradient(49.1% 50% at 50% 50%, #2C3E65 0%, #222B46 100%), #232C47;
    background-blend-mode: overlay, normal, normal;
  `}

  ${props => (props.id === 'middle-section') && css`
    background: url(${middle}), linear-gradient(122.1deg, #F9F9F9 54.43%, rgba(255, 255, 255, 0) 54.44%);
    background-blend-mode: multiply, normal;
  `}
`

export const LayoutGrid = styled.div`
  display: grid;
  width: ${props => props.wide ? "80vw" : "60vw"};
  margin: ${props => props.contactMargin ? "50vh auto auto auto" : "auto"};
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${props => props.customGap ? "1rem 2rem" : "2.5rem 2.5rem"};
  padding: ${({customPadding}) => handlePadding(customPadding)};
  min-height: ${({minHeight}) => handleMinHeight(minHeight)};
  align-content: ${props => props.alignStart ? "start" : "center"};

  @media screen and (max-width: 1400px) {
    width: ${props => props.wide ? "80vw" : "65vw"};
  }

  @media screen and (max-width: 1100px) {
    width: ${props => props.wide ? "85vw" : "75vw"};
    margin: ${props => props.contactMargin ? "10vh auto auto auto" : "auto"};
  }

  @media screen and (max-width: 800px) {
    min-height: auto;
    width: 90%;
    grid-template-columns: 1fr;
    margin: auto;
    padding-top: ${props => props.solutionsMobile && "50vh"};
  }
`

export const NewLayoutGrid = styled.div`
  display: grid;
  width: ${props => props.wide ? "80vw" : "60vw"};
  margin: ${props => props.contactMargin ? "50vh auto auto auto" : "auto"};
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${props => props.customGap ? "1rem 2rem" : "2.5rem 2.5rem"};
  padding: ${({customPadding}) => handlePadding(customPadding)};
  min-height: ${({minHeight}) => handleMinHeight(minHeight)};
  align-content: ${props => props.alignStart ? "start" : "center"};

  @media screen and (max-width: 1400px) {
    width: ${props => props.wide ? "80vw" : "65vw"};
  }

  @media screen and (max-width: 1100px) {
    width: ${props => props.wide ? "85vw" : "75vw"};
    margin: ${props => props.contactMargin ? "10vh auto auto auto" : "auto"};
  }

  @media screen and (max-width: 800px) {
    min-height: auto;
    width: 90%;
    grid-template-columns: 1fr;
    margin: auto;
    padding-top: ${props => props.solutionsMobile && "50vh"};
  }
`

export const InlineGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2rem 1rem;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem 1rem;
  }
`