import styled from "styled-components";
import { Link } from "gatsby";
import { Body } from "../typography/Typography";

export const NavbarWrapper = styled.nav`
  background: rgba(0, 28, 68, 0.55);
  border-bottom: 0.5px solid #2a4d80;
  width: 100%;
  margin-top: 0;
  transition: margin-top 0.2s ease-out;
  position: fixed;
  z-index: 100;
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const NavbarLogo = styled.img`
  height: 40px;
  cursor: pointer;
  width: auto;
`;

export const NavbarContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 60px auto;
  max-width: 80%;
  margin: auto;
  height: 100px;
  align-items: center;
  opacity: revert !important;
`;

export const NavbarContainerMenus = styled.div`
  display: grid;
  justify-self: center;
  grid-auto-flow: column;
`;

export const NavbarContainerMenuItem = styled.div`
  cursor: pointer;
  padding: 0 0.9rem 0 0.9rem;

  &:hover {
    border-bottom: 5px solid #00aeef;
    margin-bottom: -6px;

    & > .open-menu {
      display: grid;
    }
  }
`;

export const NavbarMenuText = styled.h2`
  color: #e6e6e6;
  font-size: 18px;
  padding: 0 0.9rem 0 0.9rem;
  line-height: 90px;
`;

export const SubMenuItem = styled.div`
  position: absolute;
  grid-template-columns: repeat(${(props) => props.$count}, 1fr);
  grid-column-gap: ${(props) => (props.threeway ? "40px" : "0")};
  border: 1px solid #00aeef;
  background: ${(props) =>
    props.secondary ? "var(--menu-secondary-bg)" : "var(--menu-bg)"};
  display: none;
  top: 100px;
  z-index: 1;
  left: 10%;
  right: 10%;
  cursor: auto;

  &:hover {
    display: grid;
  }
`;

export const MenuItemSubmenu = styled.div`
  position: absolute;
  grid-template-columns: ${(props) =>
    props.threeway ? "1fr 1fr 1fr" : "1fr 3fr"};
  grid-column-gap: ${(props) => (props.threeway ? "40px" : "0")};
  border: 1px solid #00aeef;
  background: ${(props) =>
    props.secondary ? "var(--menu-secondary-bg)" : "var(--menu-bg)"};
  display: none;
  top: 100px;
  z-index: 1;
  left: 10%;
  right: 10%;
  cursor: auto;

  &:hover {
    display: grid;
  }
`;

export const MenuItemSubmenuLogo = styled.img`
  padding: 14px;
  align-self: center;
  height: 30px;
  margin-bottom: 20px;
`;

export const MenuItemSubmenuColumn = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-content: start;
  padding: ${(props) => (props.information ? "10px 80px 10px 80px" : "10px")};
  background: ${(props) =>
    props.secondary ? "var(--menu-secondary-bg)" : "var(--menu-bg)"};
  min-height: 500px;

  h2 {
    border-bottom: 1px solid #ffffff;
    padding-bottom: 20px;
  }

  > h2:first-child {
    padding-top: 50px;
  }
`;

export const MenuItemSubmenuText = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.highlighted ? "var(--primary-brand)" : "white")};
  padding: 10px 0 10px 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: var(--p-small);
  line-height: var(--p-small-lh);
  padding-left: ${(props) => (props.left ? "1.9rem" : "0.9rem")};

  &:hover {
    color: var(--primary-brand);
  }
`;

export const MenuItemSubmenuTextA = styled.a`
  text-decoration: none;
  color: ${(props) => (props.highlighted ? "var(--primary-brand)" : "white")};
  padding: 10px 0 10px 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: var(--p-small);
  line-height: var(--p-small-lh);

  &:hover {
    color: var(--primary-brand);
  }
`;

export const MenuItemSubmenuInformationImg = styled.img`
  place-self: center;
`;

export const MenuItemSubmenuInformationText = styled(Body)`
  place-self: center;
`;

export const MenuItemSubmenuInformationDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  padding-top: 100px;
`;

export const NavbarLangSwitcherContainer = styled.div`
  display: grid;
  justify-self: start;
  width: 50px;

  select {
    border: none;
    background-color: transparent;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    color: #e6e6e6;

    option {
      color: var(--bg-base);
    }
  }
`;

export const NavbarDemoButtonContainer = styled.div`
  display: grid;
  justify-self: end;
`;

/*ProductMenuComponents*/

export const ProductSubmenuLogo = styled.img`
  align-self: center;
  padding: 0.5rem 0 0.5rem 0;
  height: 2.5rem;
  margin-bottom: var(--small);
`;

export const ProductsSubmenuDropdown = styled.div`
  display: grid;
  grid-template-columns: auto 45px;
`;

export const DropdownMenuItemText = styled.p`
  text-decoration: none;
  color: ${(props) => (props.highlighted ? "var(--primary-brand)" : "white")};
  padding: 10px 0 10px 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: var(--p-small);
  line-height: var(--p-small-lh);

  &:hover {
    color: var(--primary-brand);
  }
`;

export const ProductDropdownPanel = styled.div`
  max-height: ${(props) => (props.open ? props.count * 2.25 + "rem" : "0")};
  overflow: ${(props) => (props.open ? "" : "hidden")};
  grid-gap: 0.5rem;
  transition: ${(props) =>
    props.open ? "max-height 0.2s ease-out" : "max-height 0.2s ease-in"};
  display: grid;
`;

export const ProductDropdownLink = styled(Link)`
  padding-left: 3rem;
  font-style: normal;
  font-weight: 500;
  font-size: var(--p-small);
  line-height: var(--p-small-lh);
  color: white;
  &:hover {
    color: var(--primary-brand);
  }
`;
