import styled, {css} from "styled-components";
import {Link} from "gatsby";

export const SideNavigationWrapper = styled.nav`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  white-space: nowrap;
  background: transparent;
  z-index: 15;

  @media screen and (max-width: 800px) {
    right: -18vw;
  }
`

export const SideNavigationContainer = styled.ul`
  background-color: white;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-size: 18px;
  display: grid;
  justify-items: center;
  grid-template-columns: 30px;

  ul li a {
    display: block;
    font-weight: 400;
    color: white;
    text-decoration: none;
    animation: CurrentNavLeave 0.2s linear forwards;
    text-align: right;
    line-height: 23px;
  }

  @media screen and (max-width: 800px) {
    ul li a {
      font-size: 0;
      color: transparent;
      line-height: 12px;
    }
  }
`

export const Dot = styled(Link)`
  display: inline-block;
  font-weight: 400;
  color: white;
  text-decoration: none;
  animation: CurrentNavLeave 0.2s linear forwards;
  text-align: right;
  line-height: 23px;
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  
  &:hover{
    background-color: transparent!important;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    margin: 0 -2px -2px -2px;
  }

  @media (max-width: 800px) {
    font-size: 0;
    color: transparent;
    line-height: 12px;
    height: 4px;
    width: 4px;
  }

  ${props => props.active==="true" && css`
    background-color: transparent!important;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    margin: 0 -2px -2px -2px;
  `};
`