import React, { useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";

import Footer from "./footer/Footer";
import SideNavigation from "../general/side-navigation/SideNavigation";
import ModalImage from "../general/modal-image/ModalImage";
import Sidebar from "./sidebar/Sidebar";
import Seo from "../general/seo/SEO";
import LightNavbar from "./navbar/LightNavbar";
import TopCTA, { TOPCTA_STATE } from "../general/TopCTA/TopCTA";
import Header from "./header/Header";

const Layout = ({ children, lang, seo, noLayout }) => {
  const data = useStaticQuery(graphql`
    {
      allSitePage {
        pages: nodes {
          path
          context {
            locale
          }
        }
      }
      allStrapiTopCta {
        topCTA: nodes {
          isOn
          locale
          slug
          title
          white_title
          slide_in_time
        }
      }
    }
  `);
  const [top_cta_state, set_top_cta_state] = useState(TOPCTA_STATE.CLOSED);

  const { pages } = data.allSitePage;
  const { topCTA } = data.allStrapiTopCta;

  const actualCTA = topCTA.find((f) => f.locale === lang);

  const { pathname } = useLocation();

  function pathTrimmer(path) {
    if (path.length > 4 && path.slice(path.length - 1, path.length) !== "/") {
      return path.slice(3, path.length);
    } else if (
      path.length > 4 &&
      path.slice(path.length - 1, path.length) === "/"
    ) {
      return path.slice(3, path.length - 1);
    } else {
      return path;
    }
  }

  const [language, setLanguage] = useState(lang);

  const en = pages
    .filter((f) => f.context.locale === "en")
    .map((item) => pathTrimmer(item.path));
  const hu = pages
    .filter((f) => f.context.locale === "hu")
    .map((item) =>
      pathTrimmer(item.path)
    ); /*item.path.length > 4 - not to skip /en/ & /hu/*/
  const fr = pages
    .filter((f) => f.context.locale === "fr")
    .map((item) => pathTrimmer(item.path));

  function langSwitching(value) {
    setLanguage(value);
    let actualPage =
      pathname.length > 4 ? pathname.slice(3, pathname.length) : pathname;
    let includePage =
      actualPage.slice(actualPage.length - 1, actualPage.length) === "/"
        ? actualPage.slice(0, actualPage.length - 1)
        : actualPage;

    let contains;
    if (value === "en") {
      contains = en.includes(includePage);
      return contains
        ? navigate(`/${value}${includePage}`)
        : navigate("/" + value);
    } else if (value === "hu") {
      contains = hu.includes(includePage);
      return contains
        ? navigate(`/${value}${includePage}`)
        : navigate("/" + value);
    } else if (value === "fr") {
      contains = fr.includes(includePage);
      return contains
        ? navigate(`/${value}${includePage}`)
        : navigate("/" + value);
    } else {
      navigate("/");
    }
  }

  return (
    <>
      <Seo
        title={seo.title && seo.title}
        description={seo.description && seo.description}
        image={seo.preview && seo.preview.url}
        lang={lang && lang}
        keywords={seo.keywords && seo.keywords}
        isIndexable={seo.isIndexable && seo.isIndexable}
      />
      {!noLayout && actualCTA && actualCTA.isOn && (
        <TopCTA
          id={"TopCTA"}
          title={actualCTA.title}
          white_title={actualCTA.white_title}
          slug={actualCTA.slug}
          top_cta_state={top_cta_state}
          set_top_cta_state={set_top_cta_state}
          slide_in_time={actualCTA.slide_in_time}
        />
      )}
      {noLayout ? (
        <>
          <LightNavbar lang={language} setLang={langSwitching} />
          {children}
        </>
      ) : (
        <>
          <Header lang={language} setLang={langSwitching} />
          <Sidebar
            lang={language}
            setLang={langSwitching}
            top_cta_state={top_cta_state}
          />
          <SideNavigation />
          {children}
          <ModalImage />
          <Footer lang={language} />
        </>
      )}
    </>
  );
};

export default Layout;
