import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import IconButton from "../../atoms/IconButton/IconButton";

import {Body} from "../../styled/typography/Typography";
import {TopCTAContainer, ButtonContainer, Content, TopCTALink} from './TopCTA.styled'

import arrow from "../../../../static/assets/right_point_arrow.png"

export const TOPCTA_STATE = {
    OPENED: "Opened",
    CLOSED: "Closed"
}

export const XIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width={props.width || "1.125rem"}
             height={props.height || "1.125rem"} fill="none">
            <path
                d="M3.28045 4.341C2.98756 4.04811 2.98756 3.57323 3.28045 3.28034C3.57334 2.98745 4.04822 2.98745 4.34111 3.28034L14.5942 13.5334C14.8871 13.8263 14.8871 14.3012 14.5942 14.594C14.3013 14.8869 13.8264 14.8869 13.5335 14.594L3.28045 4.341Z"
                fill={props.color || "#FFFFFF"}/>
            <path
                d="M13.5333 3.28034C13.8262 2.98745 14.301 2.98745 14.5939 3.28034C14.8868 3.57323 14.8868 4.04811 14.5939 4.341L4.34088 14.594C4.04798 14.8869 3.57311 14.8869 3.28022 14.594C2.98732 14.3012 2.98732 13.8263 3.28022 13.5334L13.5333 3.28034Z"
                fill={props.color || "#FFFFFF"}/>
        </svg>
    )
}

export default function TopCTA({
                                   id, title, white_title, slug, top_cta_state, set_top_cta_state, slide_in_time,
                                   ...props
                               }) {
    useEffect(() => {
        setTimeout(() => {
            set_top_cta_state(TOPCTA_STATE.OPENED)
        }, slide_in_time*1000)
    }, [id, set_top_cta_state, slide_in_time])

    const onClose = () => {
        set_top_cta_state(TOPCTA_STATE.CLOSED)
    }

    return <TopCTAContainer id={id} top_cta_state={top_cta_state} {...props}>
        <Content>
            <Body color={"secondary-blue"}>{title} <TopCTALink to={slug}>
                {white_title} <img src={arrow} alt={"arrow"}/>
            </TopCTALink></Body>
        </Content>
        <ButtonContainer>
            <IconButton icon={<XIcon/>} onClick={onClose}/>
        </ButtonContainer>
    </TopCTAContainer>
}

TopCTA.propTypes = {
    /**
     * Component ID
     */
    id: PropTypes.string,
    /**
     * Component title
     */
    title: PropTypes.string,
    /**
     * Component button
     */
    button: PropTypes.object,
    /**
     * Component set_top_position
     */
    top_cta_state: PropTypes.oneOf(Object.values(TOPCTA_STATE)),
    /**
     * Component set_top_cta_state
     */
    set_top_cta_state: PropTypes.func,
}

TopCTA.defaultProps = {
    id: undefined,
    title: undefined,
    button: undefined,
    top_cta_state: undefined,
    set_top_cta_state: () => {
    },
}