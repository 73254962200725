import React from 'react';
import styled from "styled-components";
import {Link} from "gatsby";

import {NavbarLangSwitcherContainer, NavbarLogo} from "../../styled/layouts/NavbarComponents";

import logo from "../../../../static/assets/navbar/balasys_logo_dark_horizontal.svg"


export const LightNavbarWrapper = styled.div`
  position: absolute;
  width: 100%;
  padding: 50px 0 50px 0;
`

export const LightNavbarInnerWrapper = styled.div`
  width: 60vw;
  margin: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  
  div{
    justify-self: end;
  }

  @media screen and (max-width: 1400px) {
    width: 65vw;
  }

  @media screen and (max-width: 1100px) {
    width: 75vw;
  }

  @media screen and (max-width: 800px) {
    width: 90%;
  }
`

const LightNavbar = ({lang, setLang}) => {
    return (
        <LightNavbarWrapper>
            <LightNavbarInnerWrapper>
                <Link to={lang === "hu" ? "/hu" : "/en"}>
                    <NavbarLogo src={logo} alt="Balasys Logo"/>
                </Link>
                <NavbarLangSwitcherContainer>
                    <select
                        id="lang-switcher"
                        value={lang}
                        onChange={(e)=>setLang(e.target.value)}
                    >
                        <option value="hu">HU</option>
                        <option value="en">EN</option>
                        <option value="fr">FR</option>
                    </select>
                </NavbarLangSwitcherContainer>
            </LightNavbarInnerWrapper>
        </LightNavbarWrapper>
    );
};

export default LightNavbar;