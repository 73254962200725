import styled from 'styled-components'

import { ICONBUTTON_STATE } from './IconButton'

export const StyledIconButton = styled.button`
	align-items: center;
	display: flex;
	background: none;
	border: none;
	color: currentColor;
	cursor: pointer;
	justify-content: center;
	padding: 0;
	transition: 0.3s;
	width: fit-content;

	${props => props.state !== ICONBUTTON_STATE.ACTIVE ? `opacity: 0.3;` : ``}
	
	&:hover {
		opacity: 0.7;
	}
	
	&:active {
		opacity: 0.5;
	}

	&:disabled {
		opacity: 0.3;
		cursor: default;
	}
`