import React, { Fragment } from "react";
import _ from "lodash";

import {
  MenuItemSubmenuColumn,
  MenuItemSubmenuLogo,
  MenuItemSubmenuText,
  MenuItemSubmenuTextA,
  NavbarContainerMenuItem,
  NavbarMenuText,
  SubMenuItem,
} from "../../styled/layouts/NavbarComponents";
import { Header2Menu } from "../../styled/typography/Typography";

const MenuItem = ({ data }) => {
  const hasCategories = data.sub_menu_items.some((item) => item.category);
  const groupedMenuItems = hasCategories
    ? _.groupBy(data.sub_menu_items, "category")
    : null;
  const groupedCategories = hasCategories
    ? _.groupBy(data.categories, "title")
    : null;

  return (
    <NavbarContainerMenuItem>
      <NavbarMenuText>{data.title}</NavbarMenuText>
      <SubMenuItem
        $count={hasCategories ? Object.keys(groupedMenuItems).length : 1}
        className="open-menu"
      >
        {hasCategories ? (
          _.map(groupedMenuItems, (item, key) => (
            <MenuItemSubmenuColumn key={key}>
              {groupedCategories[key]?.[0]?.image?.url && (
                <MenuItemSubmenuLogo
                  src={groupedCategories[key][0].image.url}
                  alt="logo-1"
                />
              )}
              <Header2Menu color="white" marginBottom="small">
                {key}
              </Header2Menu>
              {item.map((item, ind) => {
                const subitems = item.sub_menu_items?.map((subitem, subind) => {
                  if (subitem.url.includes("http")) {
                    return (
                      <MenuItemSubmenuTextA
                        href={subitem.url}
                        key={subind}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {subitem.title}
                      </MenuItemSubmenuTextA>
                    );
                  }
                  return (
                    <MenuItemSubmenuText to={subitem.url} key={subind}>
                      {subitem.title}
                    </MenuItemSubmenuText>
                  );
                });

                if (item.url.includes("http")) {
                  return (
                    <Fragment key={ind}>
                      <MenuItemSubmenuTextA
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.title}
                      </MenuItemSubmenuTextA>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 16,
                        }}
                      >
                        {subitems}
                      </div>
                    </Fragment>
                  );
                }
                return (
                  <Fragment key={ind}>
                    <MenuItemSubmenuText to={item.url} key={item.id}>
                      {item.title}
                    </MenuItemSubmenuText>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 32,
                      }}
                    >
                      {subitems}
                    </div>
                  </Fragment>
                );
              })}
            </MenuItemSubmenuColumn>
          ))
        ) : (
          <MenuItemSubmenuColumn>
            {data.sub_menu_items.map((item, ind) => {
              const subitems = item.sub_menu_items?.map((subitem, subind) => {
                if (subitem.url.includes("http")) {
                  return (
                    <MenuItemSubmenuTextA
                      href={subitem.url}
                      key={subind}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {subitem.title}
                    </MenuItemSubmenuTextA>
                  );
                }
                return (
                  <MenuItemSubmenuText to={subitem.url} key={subind}>
                    {subitem.title}
                  </MenuItemSubmenuText>
                );
              });
              if (item.url.includes("http")) {
                return (
                  <Fragment key={ind}>
                    <MenuItemSubmenuTextA
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.title}
                    </MenuItemSubmenuTextA>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 16,
                      }}
                    >
                      {subitems}
                    </div>
                  </Fragment>
                );
              }
              return (
                <Fragment key={ind}>
                  <MenuItemSubmenuText to={item.url}>
                    {item.title}
                  </MenuItemSubmenuText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 32,
                    }}
                  >
                    {subitems}
                  </div>
                </Fragment>
              );
            })}
          </MenuItemSubmenuColumn>
        )}
      </SubMenuItem>
    </NavbarContainerMenuItem>
  );
};

export default MenuItem;
