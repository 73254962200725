import styled from 'styled-components'

import {TOPCTA_STATE} from './TopCTA';
import {Link} from "gatsby";

export const TopCTAContainer = styled.div`
  align-items: center;
  background: #00AEEF;
  display: grid;
  height: 3rem;
  justify-content: center;
  margin-top: ${props => props.top_cta_state === TOPCTA_STATE.CLOSED ? `-3.125rem` : `0`};
  padding: 0 4rem;
  position: sticky;
  top: ${props => props.top_cta_state === TOPCTA_STATE.CLOSED ? `-3.125rem` : `0`};
  transition: 0.4s;
  z-index: 110;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    text-align: center;
    height: 6rem;
    top: ${props => props.top_cta_state === TOPCTA_STATE.CLOSED ? `-6.125rem` : `0`};
    margin-top: ${props => props.top_cta_state === TOPCTA_STATE.CLOSED ? `-6.125rem` : `0`};
  }
`

export const Content = styled.div`
  align-items: start;
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 800px) {

  }
`

export const TopCTALink = styled(Link)`

  font-style: normal;

  &:hover {
    color: white !important;
    opacity: 0.7;
  }
  
  img{
    width: 14px;
    height: 14px;
  }
`

export const ButtonContainer = styled.div`
  position: absolute;
  right: 2.5rem;
`