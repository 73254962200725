import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import MenuItem from "./MenuItem";

import {
  NavbarContainer,
  NavbarContainerMenus,
  NavbarDemoButtonContainer,
  NavbarLangSwitcherContainer,
  NavbarLogo,
  NavbarWrapper,
} from "../../styled/layouts/NavbarComponents";
import { ButtonLink } from "../../styled/button/ButtonComponents";

const Header = ({ lang, setLang }) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiHeader {
        header: nodes {
          button {
            title
            url
          }
          logo {
            url
          }
          menu {
            title
            url
            sub_menu_items {
              category
              title
              url
              sub_menu_items {
                title
                url
              }
            }
            categories {
              title
              image {
                url
              }
            }
          }
        }
      }
    }
  `);

  const {
    allStrapiHeader: { header },
  } = data;

  const actualData = lang === "hu" ? header[0] : header[1];
  const [scrollDir, setScrollDir] = useState("");

  useEffect(() => {
    const threshold = 5;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    if (scrollDir === "scrolling up") {
      document.getElementById("headerNavigation").style.backgroundColor =
        "#001c44";
      document.getElementById("headerNavigation").style.marginTop = "0px";
    } else if (scrollDir === "scrolling down") {
      document.getElementById("headerNavigation").style.marginTop = "-105px";
    }
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  return (
    <NavbarWrapper id="headerNavigation">
      <NavbarContainer>
        <Link to={lang === "hu" ? "/hu" : "/en"}>
          <NavbarLogo src={actualData.logo.url} alt="Balasys Logo" />
        </Link>
        <NavbarContainerMenus>
          {actualData.menu.map((item, ind) => (
            <MenuItem key={ind} data={item} />
          ))}
        </NavbarContainerMenus>
        <NavbarLangSwitcherContainer>
          <select
            id="lang-switcher"
            value={lang}
            onChange={(e) => setLang(e.target.value)}
          >
            <option value="hu">HU</option>
            <option value="en">EN</option>
          </select>
        </NavbarLangSwitcherContainer>
        <NavbarDemoButtonContainer>
          <ButtonLink color="live-primary" to={actualData.button.url}>
            {actualData.button.title}
          </ButtonLink>
        </NavbarDemoButtonContainer>
      </NavbarContainer>
    </NavbarWrapper>
  );
};

export default Header;
