import React, { Fragment, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import {
  HamburgerIcon,
  HamburgerIconWrapper,
  HamburgerWrapper,
  SidebarBox,
  SidebarContainer,
  SidebarLogo,
  SidebarSubmenu,
  SidebarSubmenuArrow,
  SidebarSubmenuPanel,
} from "../../styled/layouts/SidebarComponents";
import { Body } from "../../styled/typography/Typography";

import dark from "/src/images/sidebar/BalasysDark.svg";
import light from "/src/images/sidebar/Balasyslight.svg";
import down from "/src/images/sidebar/down.svg";
import _ from "lodash";

const Sidebar = ({ lang, setLang, top_cta_state }) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiHeader {
        header: nodes {
          button {
            title
            url
          }
          menu {
            title
            url
            sub_menu_items {
              category
              title
              url
              sub_menu_items {
                title
                url
              }
            }
          }
        }
      }
    }
  `);

  const {
    allStrapiHeader: { header },
  } = data;

  const [isSidebar, setIsSidebar] = useState(false);
  const [counter, setCounter] = useState(0);
  const [subMenuOpen, setSubMenuOpen] = useState(0);

  const actualData = lang === "hu" ? header[0] : header[1];

  function menuOpen() {
    setIsSidebar(!isSidebar);
    setCounter(counter + 1);
    if (!isSidebar) {
      //document.getElementsByTagName("body")[0].style.overflowY="hidden";
    } else if (isSidebar) {
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    }
  }

  return (
    <SidebarContainer>
      <Link to={lang === "hu" ? "/" : "/en/"}>
        <SidebarLogo
          top_cta_state={top_cta_state}
          src={!isSidebar ? light : dark}
          alt="Light"
        />
      </Link>
      <HamburgerWrapper onClick={() => menuOpen()} aria-hidden={"true"}>
        <HamburgerIconWrapper top_cta_state={top_cta_state}>
          <HamburgerIcon first={!isSidebar} open1={isSidebar} />
          <HamburgerIcon mid={!isSidebar} open2={isSidebar} />
          <HamburgerIcon mid={!isSidebar} open3={isSidebar} />
          <HamburgerIcon last={!isSidebar} open4={isSidebar} />
        </HamburgerIconWrapper>
      </HamburgerWrapper>
      <SidebarBox open={isSidebar} close={counter !== 0 && !isSidebar}>
        <Body
          bold
          pointer
          onClick={() => setLang(lang === "hu" ? "en" : "hu")}
          aria-hidden={"true"}
        >
          {lang === "hu" ? "EN" : "HU"}
        </Body>
        {actualData.menu.map((item, index) => {
          const hasCategories = item.sub_menu_items.some(
            (item) => item.category
          );
          const groupedMenuItems = hasCategories
            ? _.groupBy(item.sub_menu_items, "category")
            : null;

          return (
            <Fragment key={index}>
              <SidebarSubmenu
                onClick={() =>
                  subMenuOpen !== index + 1
                    ? setSubMenuOpen(index + 1)
                    : setSubMenuOpen(0)
                }
                aria-hidden={"true"}
              >
                <p>{item.title}</p>
                <SidebarSubmenuArrow active={subMenuOpen === index + 1}>
                  <img src={down} alt="arrow" />
                </SidebarSubmenuArrow>
              </SidebarSubmenu>
              <SidebarSubmenuPanel
                open={subMenuOpen === index + 1}
                count={
                  groupedMenuItems.length +
                  item.sub_menu_items.length +
                  item.sub_menu_items.flatMap((item) => item.sub_menu_items)
                    .length
                }
              >
                {_.map(groupedMenuItems, (subItem, key) => (
                  <Fragment key={key}>
                    {key !== item.title && (
                      <p style={{ fontWeight: "bold" }}>{key}</p>
                    )}
                    {subItem.map((subItem, subindex) => {
                      return (
                        <Fragment key={subindex}>
                          <Link to={subItem.url ?? ""}>{subItem.title}</Link>
                          {subItem.sub_menu_items?.map(
                            (subsubItem, subsubindex) => {
                              return (
                                <Link
                                  key={subsubindex}
                                  to={subsubItem.url}
                                  style={{
                                    marginLeft: "40px",
                                  }}
                                >
                                  {subsubItem.title}
                                </Link>
                              );
                            }
                          )}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                ))}
              </SidebarSubmenuPanel>
            </Fragment>
          );
        })}
      </SidebarBox>
    </SidebarContainer>
  );
};

export default Sidebar;
