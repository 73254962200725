import React, {useEffect, useRef, useState} from "react";

import {
    Dot,
    SideNavigationContainer,
    SideNavigationWrapper
} from "../../styled/general/side-navigation/SideNavigationComponents";

import "./sidenavigation.css";

const SideNavigation = () => {

    const [sections, setSections] = useState()
    const myNavigation = useRef(null);

    useEffect(() => {
        setSections(Array.from(document.getElementsByTagName("section")))
    }, [])

    useEffect(() => {
        let SideNavigation = myNavigation.current
        const x = document.getElementsByTagName("section")
        const offsetsTop = []
        const offsetsHeight = []
        let counter = 0
        for (let i = 0; i < x.length; i++) {
            if (x[i].id) {
                offsetsTop[counter] = x[i].offsetTop
                offsetsHeight[counter] = x[i].offsetHeight
                counter++
            }
        }
        window.addEventListener('scroll', (event) => {
            let fromTop = window.scrollY + 50;
            SideNavigation.childNodes.forEach((node, index) => {
                if (fromTop >= offsetsTop[index] && (offsetsTop[index] + offsetsHeight[index]) > fromTop) {
                    node.children[0].className = "dot dot--active"
                } else {
                    node.children[0].className = "dot"
                }
            })
        })
    })

    return (
        <SideNavigationWrapper>
            <SideNavigationContainer ref={myNavigation}>
                {sections && sections.map((item, index) =>
                    <li key={index}>
                        <Dot to={`#${item.id}`}/>
                    </li>
                )}
            </SideNavigationContainer>
        </SideNavigationWrapper>
    )
}

export default SideNavigation