import React from "react";
import {graphql, useStaticQuery, Link} from "gatsby";

import {Header3} from "../../styled/typography/Typography";
import {FooterContainer, FooterDiv, FooterWrapper} from "../../styled/layouts/FooterComponents";

const Footer = ({lang}) => {
    const data = useStaticQuery(graphql`
    {
      allStrapiFooters {
        nodes {
          lang
          section {
            title
            items {
              id
              name
              url
            }
          }
          footer_logo {
            url
          }
        }
      }
    }
  `)

    const {allStrapiFooters: {nodes}} = data
    const actualData = nodes.filter(f=> f.lang===lang)[0];
    
    return (
        <FooterWrapper>
            <FooterContainer>
                <img src={actualData.footer_logo.url} alt="Balasys logo"/>
                <FooterDiv>
                    <Header3 color="primary-brand" marginBottom="small">
                        {actualData.section[0].title}
                    </Header3>
                    {actualData.section[0].items.map((item) => {
                        const {name, url, id} = item
                        return (
                            <Link to={url} key={id}>{name}</Link>
                        )
                    })}
                </FooterDiv>
                <FooterDiv>
                    <Header3 color="primary-brand" marginBottom="small">
                        {actualData.section[1].title}
                    </Header3>
                    {actualData.section[1].items.map((item) => {
                        const {name, url, id} = item
                        return (
                            <Link to={url} key={id}>{name}</Link>
                        )
                    })}
                </FooterDiv>
                <FooterDiv>
                    <Header3 color="primary-brand" marginBottom="small">
                        {actualData.section[2].title}
                    </Header3>
                    <Link to={actualData.section[2].items[0].url}>{actualData.section[2].items[0].name}</Link>
                    <a href={actualData.section[2].items[1].url} target="_blank"
                       rel="noreferrer">{actualData.section[2].items[1].name}</a>
                </FooterDiv>
                <FooterDiv>
                    <Header3 color="primary-brand" marginBottom="small">
                        {actualData.section[3].title}
                    </Header3>
                    <p>{actualData.section[3].items[0].name}</p>
                    <a href={"mailto:" + actualData.section[3].items[1].name}>{actualData.section[3].items[1].name}</a>
                    <p>{actualData.section[3].items[2].name}</p>
                </FooterDiv>
            </FooterContainer>
        </FooterWrapper>
    )
}

export default Footer